import { Stack, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { LayoutContext } from "../layout/Layout";
import Perch from "../img/companies/perch.png";
import LineLeap from "../img/companies/lineleap.png";
import Meetingbird from "../img/companies/meetingbird.png";
import Groover from "../img/companies/groover.png";
import SwineTech from "../img/companies/swinetech.png";
import CIMU from "../img/companies/cimu.jpeg";
import AdmitYogi from "../img/companies/yogi.png";
import WaveTheWait from "../img/companies/waivethewait.png";
import CompanyCard from "./CompanyCard";

const companies = [
  [
    "CIMU",
    "Cimu helps online, middle-market fashion retailers reduce returns by connecting consumers with easy and accessible clothing alterations.",
    CIMU,
    ["TL 2023"],
  ],
  [
    "Admit Yogi",
    "Admit Yogi is democratizing college admissions for millions of high schoolers with the power of Big Data and Artificial Intelligence.",
    AdmitYogi,
    ["TL 2022"],
  ],
  [
    "WaveTheWait",
    "WaiveTheWait is a patient communications company that optimizes clinical operations by automating routine and complex tasks to reduce operational costs, increase revenue, and reduce staff burnout.",
    WaveTheWait,
    ["TL 2021"],
  ],
  [
    "Perch",
    "Perch operates as an intermediary between banks and college students, enabling students to build their credit score without a credit card or loan. Through this, Perch aims to realign financial institutions with the college market.",
    Perch,
    ["TL 2020", "YC 2020"],
  ],
  [
    "LineLeap",
    'LineLeap is a mobile platform providing users with the ability to purchase "fast pass entry" at their favorite venues.',
    LineLeap,
    ["TL 2019", "YC 2019"],
  ],
  [
    "Groover",
    "Groover helps musicians and their representatives to send tunes directly to carefully selected blogs, record labels, and other music influencers of their choice.",
    Groover,
    ["TL 2019", "Seed $1.3M"],
  ],
  [
    "Meetingbird",
    "Meetingbird is an automated and modern scheduling platform that aims to homogenize scheduling among major web platforms like Slack, Gmail, and zoom. As of 2020, it has been acquired by Front.",
    Meetingbird,
    ["TL 2016", "YC 2017"],
  ],
  [
    "SwineTech",
    "SwineTech aims to revolutionize swine health within the pork industry by introducing health sensors that monitor piglet health, thereby reducing revenue loss.",
    SwineTech,
    ["TL 2016", "Seed $2.8M"],
  ],
];

export default function Companies() {
  const theme = useTheme();

  return (
    <Stack>
      <Stack
        sx={{
          ...theme.darkBg,
          overflow: "hidden",
        }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Stack
          sx={{
            ...theme.container,
          }}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack
            sx={{
              position: "relative",
              marginTop: "12rem",
              paddingBottom: "8rem",
              width: "100%",
            }}
            alignItems="center"
            spacing={4}
          >
            <Stack
              sx={{
                ...theme.font.h0,
                color: "white",
                py: 2,
                borderBottom: "1px solid white",
                width: "100%",
                textAlign: "center",
              }}
            >
              Companies
            </Stack>
            <Stack
              sx={{
                ...theme.font.h4,
                color: "white",
                pb: 2,
                width: "100%",
                textAlign: "center",
              }}
            >
              Since our founding in 2014, our competitors have raised a
              cumulative $1.4B in venture funding, and have been accepted to
              YCombinator every year.
            </Stack>
            <Stack
              sx={{
                width: "100%",
              }}
              spacing={4}
            >
              {companies.map((v) => {
                return (
                  <CompanyCard
                    name={v[0]}
                    desc={v[1]}
                    imgUrl={v[2]}
                    tags={v[3]}
                  />
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
